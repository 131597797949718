@use "styles" as *;

.carousel {
  display: flex;
}

.container {
  position: relative;
  overflow: hidden;
}

.innerContainer {
  position: absolute;
  bottom: 16px;
  left: 0;
  right: 0;
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  z-index: 1;
  column-gap: 1px;

  .bulletsWrapper {
    display: flex;
    gap: 16px;
    padding-left: 15px;
    @include mobile-only {
      grid-column: 3 / 12;
    }
    @include sm-desktop {
      grid-column: 2 / 12;
    }
    @include md-desktop {
      grid-column: 1 / span 4;
    }
    @include lg-desktop {
      padding-left: 0;
    }
  }

  @include sm-desktop {
    column-gap: 12px;
  }

  @include md-desktop {
    bottom: 32px;

    &.centerPanel {
      .bullet {
        width: 100%;
      }
      .bulletsWrapper {
        grid-column: 4 / span 6;
      }
    }
  }
}
.pause {
  @include glass-gradient;
  @include glass-shadow;
  backdrop-filter: blur(10px);
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  svg {
    width: 14px;
    height: 14px;
  }

  @include md-desktop {
    height: 32px;
    width: 32px;
    svg {
      width: 24px;
      height: 24px;
    }
  }
}

.carouselBullets {
  // default animation duration
  --autoplay-duration: 1000ms;
  display: flex;
  align-items: center;
  gap: 8px;
  flex-grow: 1;
  justify-content: flex-end;
  @include mobile-only {
    gap: 4px;
  }
}

.bullet {
  flex: 1 1 auto;
  position: relative;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 4px;
  height: 2px;
  // for increasing focus area
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: -0.5rem;
    bottom: -0.5rem;
    right: 0;
    z-index: 1;
  }
  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 0;
    border-radius: 4px;
    width: 0;
    z-index: 1;
    background: $white;
  }
  @include md-desktop {
    width: max(4vw, 50px);
    flex-grow: 0;
  }
}

.bulletActive {
  &::after {
    width: 100%;
  }
}

@keyframes fill-bar {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

.bulletAutoplayActive {
  &::after {
    animation-name: fill-bar;
    animation-duration: var(--autoplay-duration);
    animation-timing-function: linear;
    animation-fill-mode: forwards;
  }
}
